/**
 * 구글 시트로 관리되는 json 파일 이름 리스트
 * 쉽다 웹 한글 문구 및 번역 문구 각 시트를 로컬 json파일로 변경시 사용 할 이름
 * 탭 이름을 기준으로 작성
 *
 * @example
 * shared                        : shared (공통(프로덕트 공용 문구))
 * common-new                    : common-new (공용 UI(프로덕트 공통 컴포넌트))
 * sds-v1                        : V1 디자인시스템
 * sds-v2                        : V2 디자인시스템
 * sds-v3                        : V3 디자인시스템
 * auth-new                      : 회원가입/로그인
 * main-landing                  : 랜딩
 * company-landing               : 회사소개 페이지
 * page-mypage-bid               : 마이페이지 - 수입/수출운송
 * page-mypage-bid-exportation   : 마이페이지 - 수출운송에서만 사용되는 문구
 * partner                       : 마이페이지 - 업체관리
 * support-new                   : 가이드/공지사항/동영상
 * company-info                  : 마이페이지 - 회사정보 - 정보변경
 * team                          : 마이페이지 - 멤버관리
 * user-info                     : 마이페이지 - 사용자정보 - 정보변경
 * coupon                        : 마이페이지 - 쿠폰
 * page-mypage-dashboard         : 마이페이지 - 대시보드
 * components                    : components
 * containers                    : containers
 * hocs                          : hocs
 * hooks                         : hooks
 * utils                         : utils
 * queries                       : queries
 * constants                     : constants
 * states                        : states (recoil, jotai 모두)
 * page-forwarding-schedule      : page-서비스-스케줄 조회
 * page-forwarding-quote         : page-서비스-견적의뢰
 */
const newJsonFileNameList = [
  "shared",
  "common-new",
  "sds-v1",
  "sds-v2",
  "sds-v3",
  "auth-new",
  "main-landing",
  "company-landing",
  "page-mypage-bid",
  "page-mypage-bid-exportation",
  "partner",
  "support-new",
  "company-info",
  "team",
  "user-info",
  "coupon",
  "page-mypage-dashboard",
  "components",
  "containers",
  "hocs",
  "hooks",
  "utils",
  "queries",
  "constants",
  "states",
  "page-forwarding-schedule",
  "page-forwarding-quote",
] as const;

/**
 * 구글 시트에 없는 이전 json 파일 이름 리스트
 */
const legacyJsonFileNameList = [
  "common",
  "errors",
  "mypage",
  "privacy",
  "terms",
] as const;

const jsonFileNameList = [
  ...newJsonFileNameList,
  ...legacyJsonFileNameList,
] as const;

type JsonFileNameList = (typeof jsonFileNameList)[number];

export type { JsonFileNameList };

export { newJsonFileNameList, jsonFileNameList };
