import { Cluster } from "@googlemaps/markerclusterer";
import { useTranslation } from "next-i18next";

import Loading from "@sellernote/_sds-v2/src/components/Loading";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import Icon from "../../../../componentsToMoveToV1/Icon";
import { MarkerClustererInfo, MarkerInfo } from "..";
import useGoogleMap from "./useGoogleMap";
import Styled from "./index.styles";

export interface MapProps extends google.maps.MapOptions {
  markerInfoList?: MarkerInfo[];
  polylineInfo?: google.maps.PolylineOptions;
  dashedPolylineInfo?: google.maps.PolylineOptions;
  setMarkerCluster?: (cluster: Cluster) => MarkerClustererInfo;
  onGoogleApiLoaded?: (maps: { map: any }) => void;
  isAutoZoom?: boolean;
  boundsPadding?: google.maps.Padding;
  hasError?: boolean;
}

export default function Map({
  markerInfoList,
  polylineInfo,
  dashedPolylineInfo,
  setMarkerCluster,
  onGoogleApiLoaded,
  style,
  isAutoZoom,
  boundsPadding,
  hasError,
  minZoom,
  ...options
}: MapProps & { style: { [key: string]: string } }) {
  const { t } = useTranslation();

  const { ref, isMapLoading, isMapLoadError } = useGoogleMap({
    markerInfoList,
    polylineInfo,
    dashedPolylineInfo,
    setMarkerCluster,
    onGoogleApiLoaded,
    isAutoZoom,
    boundsPadding,
    hasError,
    minZoom,
    ...options,
  });

  return (
    <Styled.container>
      {isMapLoading && (
        <Styled.dimmedLayer>
          <Loading
            direction="row"
            message={t("components:googleMap.잠시만_기다려주세요...")}
            messageColor={TEXT_COLOR.white_1}
          />
        </Styled.dimmedLayer>
      )}

      {(hasError || isMapLoadError) && (
        <Styled.dimmedLayer>
          <Styled.message>
            <Icon type="warning" color={COLOR.error_400} />

            <div className="text">
              {t("components:googleMap.불러오기를_실패했습니다.")}
            </div>
          </Styled.message>
        </Styled.dimmedLayer>
      )}

      {/* 맵 생성 전 휠 스크롤 줌을 사용하는 상황에서 에러가 나와 조건 추가 */}
      {ref ? <div ref={ref} style={style} /> : null}
    </Styled.container>
  );
}
